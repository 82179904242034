import { render, staticRenderFns } from "./indices.vue?vue&type=template&id=1df21085"
import script from "./indices.vue?vue&type=script&lang=js"
export * from "./indices.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/indices-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fproducts%2Findices.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/indices-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fproducts%2Findices.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default,PageIntroBlock: require('/vercel/path0/components/PageIntroBlock.vue').default,Decorator: require('/vercel/path0/components/ui/decorator.vue').default,FeaturesGridTwo: require('/vercel/path0/components/FeaturesGridTwo.vue').default,StartTradingSteps: require('/vercel/path0/components/StartTradingSteps.vue').default,CtaImage: require('/vercel/path0/components/ui/cta-image.vue').default})
