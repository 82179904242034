import { render, staticRenderFns } from "./BrokersListByService.vue?vue&type=template&id=49953f9e"
import script from "./BrokersListByService.vue?vue&type=script&lang=js"
export * from "./BrokersListByService.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BrokersListByService.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MemberCard: require('/vercel/path0/components/MemberCard.vue').default,BrokerDetails: require('/vercel/path0/components/datablocks/BrokerDetails.vue').default,FloatingModal: require('/vercel/path0/components/FloatingModal.vue').default})
