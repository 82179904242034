import { render, staticRenderFns } from "./SecurityReports.vue?vue&type=template&id=7f5f8227"
import script from "./SecurityReports.vue?vue&type=script&lang=js"
export * from "./SecurityReports.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./SecurityReports.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingAnimation: require('/vercel/path0/components/ui/LoadingAnimation.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default,CorporateGovernanceReportCard: require('/vercel/path0/components/CorporateGovernanceReportCard.vue').default,SecurityReportsCard: require('/vercel/path0/components/SecurityReportsCard.vue').default})
