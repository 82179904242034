import { render, staticRenderFns } from "./VideoThumbnailCard.vue?vue&type=template&id=55f5cc15"
import script from "./VideoThumbnailCard.vue?vue&type=script&lang=js"
export * from "./VideoThumbnailCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CImage: require('/vercel/path0/components/ui/c-image.vue').default,FloatingModal: require('/vercel/path0/components/FloatingModal.vue').default})
