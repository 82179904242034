import { render, staticRenderFns } from "./personal-investors.vue?vue&type=template&id=03e027e2"
import script from "./personal-investors.vue?vue&type=script&lang=js"
export * from "./personal-investors.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/personal-investors-ar.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fpersonal-investors.vue&locale=ar&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/personal-investors-en.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fpersonal-investors.vue&locale=en&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,PageIntroBlock: require('/vercel/path0/components/PageIntroBlock.vue').default,CardWithImageBg: require('/vercel/path0/components/ui/CardWithImageBg.vue').default,FootNote: require('/vercel/path0/components/FootNote.vue').default,CtaImage: require('/vercel/path0/components/ui/cta-image.vue').default,DubaiInvestmentFeatures: require('/vercel/path0/components/blocks/DubaiInvestmentFeatures.vue').default,DfmProductsGrid: require('/vercel/path0/components/blocks/DfmProductsGrid.vue').default,StartTradingSteps: require('/vercel/path0/components/StartTradingSteps.vue').default})
