import { render, staticRenderFns } from "./company-prices.vue?vue&type=template&id=4acbc072"
import script from "./company-prices.vue?vue&type=script&lang=js"
export * from "./company-prices.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locale/statistics-reports-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fstatistics-reports%2Fhistorical-data%2Fcompany-prices.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "../locale/statistics-reports-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fstatistics-reports%2Fhistorical-data%2Fcompany-prices.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DateRange: require('/vercel/path0/components/ui/DateRange.vue').default,CompanySelect: require('/vercel/path0/components/datablocks/CompanySelect.vue').default,HorizontalLoader: require('/vercel/path0/components/ui/HorizontalLoader.vue').default,BaseCard: require('/vercel/path0/components/ui/BaseCard.vue').default,PriceChange: require('/vercel/path0/components/micro/PriceChange.vue').default})
