import { render, staticRenderFns } from "./contact-us.vue?vue&type=template&id=35bf40c3&scoped=true"
import script from "./contact-us.vue?vue&type=script&lang=js"
export * from "./contact-us.vue?vue&type=script&lang=js"
import style0 from "./contact-us.vue?vue&type=style&index=0&id=35bf40c3&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35bf40c3",
  null
  
)

/* custom blocks */
import block0 from "./locale/contact-us-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fdiscover-dfm%2Fcontact-us.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/contact-us-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fdiscover-dfm%2Fcontact-us.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default})
