import { render, staticRenderFns } from "./investor-information-update.vue?vue&type=template&id=204b2d9f"
import script from "./investor-information-update.vue?vue&type=script&lang=js"
export * from "./investor-information-update.vue?vue&type=script&lang=js"
import style0 from "./investor-information-update.vue?vue&type=style&index=0&id=204b2d9f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/investor-kyc-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fservices%2Finvestor-information-update.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/investor-kyc-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fservices%2Finvestor-information-update.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppStoreLinks: require('/vercel/path0/components/blocks/AppStoreLinks.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,VideoThumbnailCard: require('/vercel/path0/components/ui/VideoThumbnailCard.vue').default,DownloadCard: require('/vercel/path0/components/DownloadCard.vue').default})
