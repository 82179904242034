import { render, staticRenderFns } from "./vue-glide-component.vue?vue&type=template&id=52ae2eb0"
import script from "./vue-glide-component.vue?vue&type=script&lang=js"
export * from "./vue-glide-component.vue?vue&type=script&lang=js"
import style0 from "./vue-glide-component.vue?vue&type=style&index=0&id=52ae2eb0&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default,CardWithImageBg: require('/vercel/path0/components/ui/CardWithImageBg.vue').default})
