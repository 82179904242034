import { render, staticRenderFns } from "./carbon-credits.vue?vue&type=template&id=1917d3a6&scoped=true"
import script from "./carbon-credits.vue?vue&type=script&lang=js"
export * from "./carbon-credits.vue?vue&type=script&lang=js"
import style0 from "./carbon-credits.vue?vue&type=style&index=0&id=1917d3a6&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1917d3a6",
  null
  
)

/* custom blocks */
import block0 from "./locale/carbon-credits-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fproducts%2Fcarbon-credits.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/carbon-credits-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fproducts%2Fcarbon-credits.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Decorator: require('/vercel/path0/components/ui/decorator.vue').default,PageHeroLarge: require('/vercel/path0/components/PageHeroLarge.vue').default,CarbonCreditsMarketWatchStatic: require('/vercel/path0/components/datablocks/CarbonCreditsMarketWatchStatic.vue').default,PageIntroBlock: require('/vercel/path0/components/PageIntroBlock.vue').default,VideoThumbnailCard: require('/vercel/path0/components/ui/VideoThumbnailCard.vue').default,FeaturesGridTwo: require('/vercel/path0/components/FeaturesGridTwo.vue').default,HalfImageHalfGrid: require('/vercel/path0/components/HalfImageHalfGrid.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,FaqsAccordion: require('/vercel/path0/components/datablocks/FaqsAccordion.vue').default,FloatingModal: require('/vercel/path0/components/FloatingModal.vue').default})
