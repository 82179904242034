export const ChildModal = () => import('../../components/ChildModal.vue' /* webpackChunkName: "components/child-modal" */).then(c => wrapFunctional(c.default || c))
export const CircularListCard = () => import('../../components/CircularListCard.vue' /* webpackChunkName: "components/circular-list-card" */).then(c => wrapFunctional(c.default || c))
export const CorporateGovernanceReportCard = () => import('../../components/CorporateGovernanceReportCard.vue' /* webpackChunkName: "components/corporate-governance-report-card" */).then(c => wrapFunctional(c.default || c))
export const DisclosureCard = () => import('../../components/DisclosureCard.vue' /* webpackChunkName: "components/disclosure-card" */).then(c => wrapFunctional(c.default || c))
export const DownloadCard = () => import('../../components/DownloadCard.vue' /* webpackChunkName: "components/download-card" */).then(c => wrapFunctional(c.default || c))
export const DropdownList = () => import('../../components/DropdownList.vue' /* webpackChunkName: "components/dropdown-list" */).then(c => wrapFunctional(c.default || c))
export const EventListCard = () => import('../../components/EventListCard.vue' /* webpackChunkName: "components/event-list-card" */).then(c => wrapFunctional(c.default || c))
export const FauxTabs = () => import('../../components/FauxTabs.vue' /* webpackChunkName: "components/faux-tabs" */).then(c => wrapFunctional(c.default || c))
export const FeaturesGridTwo = () => import('../../components/FeaturesGridTwo.vue' /* webpackChunkName: "components/features-grid-two" */).then(c => wrapFunctional(c.default || c))
export const FilterAnnouncements = () => import('../../components/FilterAnnouncements.vue' /* webpackChunkName: "components/filter-announcements" */).then(c => wrapFunctional(c.default || c))
export const FilterExchange = () => import('../../components/FilterExchange.vue' /* webpackChunkName: "components/filter-exchange" */).then(c => wrapFunctional(c.default || c))
export const FilterSearchBox = () => import('../../components/FilterSearchBox.vue' /* webpackChunkName: "components/filter-search-box" */).then(c => wrapFunctional(c.default || c))
export const FloatingModal = () => import('../../components/FloatingModal.vue' /* webpackChunkName: "components/floating-modal" */).then(c => wrapFunctional(c.default || c))
export const FlyoutMenu = () => import('../../components/FlyoutMenu.vue' /* webpackChunkName: "components/flyout-menu" */).then(c => wrapFunctional(c.default || c))
export const FlyoutMenuSection = () => import('../../components/FlyoutMenuSection.vue' /* webpackChunkName: "components/flyout-menu-section" */).then(c => wrapFunctional(c.default || c))
export const FootNote = () => import('../../components/FootNote.vue' /* webpackChunkName: "components/foot-note" */).then(c => wrapFunctional(c.default || c))
export const FooterContent = () => import('../../components/FooterContent.vue' /* webpackChunkName: "components/footer-content" */).then(c => wrapFunctional(c.default || c))
export const GeneralNewsCard = () => import('../../components/GeneralNewsCard.vue' /* webpackChunkName: "components/general-news-card" */).then(c => wrapFunctional(c.default || c))
export const HalfImageHalfGrid = () => import('../../components/HalfImageHalfGrid.vue' /* webpackChunkName: "components/half-image-half-grid" */).then(c => wrapFunctional(c.default || c))
export const HalfImageHalfGridTwo = () => import('../../components/HalfImageHalfGridTwo.vue' /* webpackChunkName: "components/half-image-half-grid-two" */).then(c => wrapFunctional(c.default || c))
export const HeaderArea = () => import('../../components/HeaderArea.vue' /* webpackChunkName: "components/header-area" */).then(c => wrapFunctional(c.default || c))
export const HeaderAreaNew = () => import('../../components/HeaderAreaNew.vue' /* webpackChunkName: "components/header-area-new" */).then(c => wrapFunctional(c.default || c))
export const HeaderInfoBar = () => import('../../components/HeaderInfoBar.vue' /* webpackChunkName: "components/header-info-bar" */).then(c => wrapFunctional(c.default || c))
export const HomePageHero = () => import('../../components/HomePageHero.vue' /* webpackChunkName: "components/home-page-hero" */).then(c => wrapFunctional(c.default || c))
export const HomePageHeroSingle = () => import('../../components/HomePageHeroSingle.vue' /* webpackChunkName: "components/home-page-hero-single" */).then(c => wrapFunctional(c.default || c))
export const HomePageHeroSingleBanner = () => import('../../components/HomePageHeroSingleBanner.vue' /* webpackChunkName: "components/home-page-hero-single-banner" */).then(c => wrapFunctional(c.default || c))
export const HomePageHeroSingleIpo = () => import('../../components/HomePageHeroSingleIpo.vue' /* webpackChunkName: "components/home-page-hero-single-ipo" */).then(c => wrapFunctional(c.default || c))
export const IconCard = () => import('../../components/IconCard.vue' /* webpackChunkName: "components/icon-card" */).then(c => wrapFunctional(c.default || c))
export const ImageFill = () => import('../../components/ImageFill.vue' /* webpackChunkName: "components/image-fill" */).then(c => wrapFunctional(c.default || c))
export const InfoBox = () => import('../../components/InfoBox.vue' /* webpackChunkName: "components/info-box" */).then(c => wrapFunctional(c.default || c))
export const IvestorBetaForm = () => import('../../components/IvestorBetaForm.vue' /* webpackChunkName: "components/ivestor-beta-form" */).then(c => wrapFunctional(c.default || c))
export const LatestDisclosureCard = () => import('../../components/LatestDisclosureCard.vue' /* webpackChunkName: "components/latest-disclosure-card" */).then(c => wrapFunctional(c.default || c))
export const LatestEventCard = () => import('../../components/LatestEventCard.vue' /* webpackChunkName: "components/latest-event-card" */).then(c => wrapFunctional(c.default || c))
export const LinkedIconCard = () => import('../../components/LinkedIconCard.vue' /* webpackChunkName: "components/linked-icon-card" */).then(c => wrapFunctional(c.default || c))
export const MainNavigation = () => import('../../components/MainNavigation.vue' /* webpackChunkName: "components/main-navigation" */).then(c => wrapFunctional(c.default || c))
export const ManagementCard = () => import('../../components/ManagementCard.vue' /* webpackChunkName: "components/management-card" */).then(c => wrapFunctional(c.default || c))
export const MarketAnnouncementCard = () => import('../../components/MarketAnnouncementCard.vue' /* webpackChunkName: "components/market-announcement-card" */).then(c => wrapFunctional(c.default || c))
export const MarketStatistics = () => import('../../components/MarketStatistics.vue' /* webpackChunkName: "components/market-statistics" */).then(c => wrapFunctional(c.default || c))
export const MarketStatus = () => import('../../components/MarketStatus.vue' /* webpackChunkName: "components/market-status" */).then(c => wrapFunctional(c.default || c))
export const MemberCard = () => import('../../components/MemberCard.vue' /* webpackChunkName: "components/member-card" */).then(c => wrapFunctional(c.default || c))
export const MemberLogoCard = () => import('../../components/MemberLogoCard.vue' /* webpackChunkName: "components/member-logo-card" */).then(c => wrapFunctional(c.default || c))
export const ModalCloseButton = () => import('../../components/ModalCloseButton.vue' /* webpackChunkName: "components/modal-close-button" */).then(c => wrapFunctional(c.default || c))
export const MostActiveTable = () => import('../../components/MostActiveTable.vue' /* webpackChunkName: "components/most-active-table" */).then(c => wrapFunctional(c.default || c))
export const MostActiveTableData = () => import('../../components/MostActiveTableData.vue' /* webpackChunkName: "components/most-active-table-data" */).then(c => wrapFunctional(c.default || c))
export const NavToScroll = () => import('../../components/NavToScroll.vue' /* webpackChunkName: "components/nav-to-scroll" */).then(c => wrapFunctional(c.default || c))
export const OnClickOutside = () => import('../../components/OnClickOutside.vue' /* webpackChunkName: "components/on-click-outside" */).then(c => wrapFunctional(c.default || c))
export const PageHero = () => import('../../components/PageHero.vue' /* webpackChunkName: "components/page-hero" */).then(c => wrapFunctional(c.default || c))
export const PageHeroLarge = () => import('../../components/PageHeroLarge.vue' /* webpackChunkName: "components/page-hero-large" */).then(c => wrapFunctional(c.default || c))
export const PageIntroBlock = () => import('../../components/PageIntroBlock.vue' /* webpackChunkName: "components/page-intro-block" */).then(c => wrapFunctional(c.default || c))
export const PageIntroBlockTwo = () => import('../../components/PageIntroBlockTwo.vue' /* webpackChunkName: "components/page-intro-block-two" */).then(c => wrapFunctional(c.default || c))
export const PieChartComponent = () => import('../../components/PieChartComponent.vue' /* webpackChunkName: "components/pie-chart-component" */).then(c => wrapFunctional(c.default || c))
export const PressReleaseCard = () => import('../../components/PressReleaseCard.vue' /* webpackChunkName: "components/press-release-card" */).then(c => wrapFunctional(c.default || c))
export const RelatedLinks = () => import('../../components/RelatedLinks.vue' /* webpackChunkName: "components/related-links" */).then(c => wrapFunctional(c.default || c))
export const ReportsPresentationsCard = () => import('../../components/ReportsPresentationsCard.vue' /* webpackChunkName: "components/reports-presentations-card" */).then(c => wrapFunctional(c.default || c))
export const ResourceCard = () => import('../../components/ResourceCard.vue' /* webpackChunkName: "components/resource-card" */).then(c => wrapFunctional(c.default || c))
export const SampleChart = () => import('../../components/SampleChart.vue' /* webpackChunkName: "components/sample-chart" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const SecurityCard = () => import('../../components/SecurityCard.vue' /* webpackChunkName: "components/security-card" */).then(c => wrapFunctional(c.default || c))
export const SecurityReports = () => import('../../components/SecurityReports.vue' /* webpackChunkName: "components/security-reports" */).then(c => wrapFunctional(c.default || c))
export const SecurityReportsCard = () => import('../../components/SecurityReportsCard.vue' /* webpackChunkName: "components/security-reports-card" */).then(c => wrapFunctional(c.default || c))
export const StartTradingSteps = () => import('../../components/StartTradingSteps.vue' /* webpackChunkName: "components/start-trading-steps" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionCard = () => import('../../components/SubscriptionCard.vue' /* webpackChunkName: "components/subscription-card" */).then(c => wrapFunctional(c.default || c))
export const Tab = () => import('../../components/Tab.vue' /* webpackChunkName: "components/tab" */).then(c => wrapFunctional(c.default || c))
export const TableComponent = () => import('../../components/TableComponent.vue' /* webpackChunkName: "components/table-component" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const TailwindBreakpoints = () => import('../../components/TailwindBreakpoints.vue' /* webpackChunkName: "components/tailwind-breakpoints" */).then(c => wrapFunctional(c.default || c))
export const TickerBlock = () => import('../../components/TickerBlock.vue' /* webpackChunkName: "components/ticker-block" */).then(c => wrapFunctional(c.default || c))
export const TimelineYear = () => import('../../components/TimelineYear.vue' /* webpackChunkName: "components/timeline-year" */).then(c => wrapFunctional(c.default || c))
export const VueSimpleAccordion = () => import('../../components/VueSimpleAccordion.vue' /* webpackChunkName: "components/vue-simple-accordion" */).then(c => wrapFunctional(c.default || c))
export const VueSimpleAccordion2 = () => import('../../components/VueSimpleAccordion2.vue' /* webpackChunkName: "components/vue-simple-accordion2" */).then(c => wrapFunctional(c.default || c))
export const VueGlideComponent = () => import('../../components/vue-glide-component.vue' /* webpackChunkName: "components/vue-glide-component" */).then(c => wrapFunctional(c.default || c))
export const CareersForm = () => import('../../components/forms/CareersForm.vue' /* webpackChunkName: "components/careers-form" */).then(c => wrapFunctional(c.default || c))
export const EboardApplicationForm = () => import('../../components/forms/EboardApplicationForm.vue' /* webpackChunkName: "components/eboard-application-form" */).then(c => wrapFunctional(c.default || c))
export const EboardNotificationForm = () => import('../../components/forms/EboardNotificationForm.vue' /* webpackChunkName: "components/eboard-notification-form" */).then(c => wrapFunctional(c.default || c))
export const BrokerDetails = () => import('../../components/datablocks/BrokerDetails.vue' /* webpackChunkName: "components/broker-details" */).then(c => wrapFunctional(c.default || c))
export const BrokersList = () => import('../../components/datablocks/BrokersList.vue' /* webpackChunkName: "components/brokers-list" */).then(c => wrapFunctional(c.default || c))
export const BrokersListByService = () => import('../../components/datablocks/BrokersListByService.vue' /* webpackChunkName: "components/brokers-list-by-service" */).then(c => wrapFunctional(c.default || c))
export const CarbonCreditsMarketStatus = () => import('../../components/datablocks/CarbonCreditsMarketStatus.vue' /* webpackChunkName: "components/carbon-credits-market-status" */).then(c => wrapFunctional(c.default || c))
export const CarbonCreditsMarketWatch = () => import('../../components/datablocks/CarbonCreditsMarketWatch.vue' /* webpackChunkName: "components/carbon-credits-market-watch" */).then(c => wrapFunctional(c.default || c))
export const CarbonCreditsMarketWatchStatic = () => import('../../components/datablocks/CarbonCreditsMarketWatchStatic.vue' /* webpackChunkName: "components/carbon-credits-market-watch-static" */).then(c => wrapFunctional(c.default || c))
export const CircularsListSmall = () => import('../../components/datablocks/CircularsListSmall.vue' /* webpackChunkName: "components/circulars-list-small" */).then(c => wrapFunctional(c.default || c))
export const CompanyCorporateActions = () => import('../../components/datablocks/CompanyCorporateActions.vue' /* webpackChunkName: "components/company-corporate-actions" */).then(c => wrapFunctional(c.default || c))
export const CompanySelect = () => import('../../components/datablocks/CompanySelect.vue' /* webpackChunkName: "components/company-select" */).then(c => wrapFunctional(c.default || c))
export const DfmgiStatistics = () => import('../../components/datablocks/Dfmgi-Statistics.vue' /* webpackChunkName: "components/dfmgi-statistics" */).then(c => wrapFunctional(c.default || c))
export const DisclosureListSmall = () => import('../../components/datablocks/DisclosureListSmall.vue' /* webpackChunkName: "components/disclosure-list-small" */).then(c => wrapFunctional(c.default || c))
export const ExploreAvailableProducts = () => import('../../components/datablocks/ExploreAvailableProducts.vue' /* webpackChunkName: "components/explore-available-products" */).then(c => wrapFunctional(c.default || c))
export const FaqsAccordion = () => import('../../components/datablocks/FaqsAccordion.vue' /* webpackChunkName: "components/faqs-accordion" */).then(c => wrapFunctional(c.default || c))
export const FaqsAccordionMulti = () => import('../../components/datablocks/FaqsAccordionMulti.vue' /* webpackChunkName: "components/faqs-accordion-multi" */).then(c => wrapFunctional(c.default || c))
export const GeneralNews = () => import('../../components/datablocks/GeneralNews.vue' /* webpackChunkName: "components/general-news" */).then(c => wrapFunctional(c.default || c))
export const GlobalNotificationBar = () => import('../../components/datablocks/GlobalNotificationBar.vue' /* webpackChunkName: "components/global-notification-bar" */).then(c => wrapFunctional(c.default || c))
export const ListedCarbonCredits = () => import('../../components/datablocks/ListedCarbonCredits.vue' /* webpackChunkName: "components/listed-carbon-credits" */).then(c => wrapFunctional(c.default || c))
export const MarketAnnouncements = () => import('../../components/datablocks/MarketAnnouncements.vue' /* webpackChunkName: "components/market-announcements" */).then(c => wrapFunctional(c.default || c))
export const SymbolSearch = () => import('../../components/datablocks/SymbolSearch.vue' /* webpackChunkName: "components/symbol-search" */).then(c => wrapFunctional(c.default || c))
export const TeamMember = () => import('../../components/datablocks/TeamMember.vue' /* webpackChunkName: "components/team-member" */).then(c => wrapFunctional(c.default || c))
export const UnclaimedDividends = () => import('../../components/datablocks/UnclaimedDividends.vue' /* webpackChunkName: "components/unclaimed-dividends" */).then(c => wrapFunctional(c.default || c))
export const AppFeaturesCarousel = () => import('../../components/blocks/AppFeaturesCarousel.vue' /* webpackChunkName: "components/app-features-carousel" */).then(c => wrapFunctional(c.default || c))
export const AppStoreLinks = () => import('../../components/blocks/AppStoreLinks.vue' /* webpackChunkName: "components/app-store-links" */).then(c => wrapFunctional(c.default || c))
export const AttachmentListDropdown = () => import('../../components/blocks/AttachmentListDropdown.vue' /* webpackChunkName: "components/attachment-list-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CenteredSectionTitle = () => import('../../components/blocks/CenteredSectionTitle.vue' /* webpackChunkName: "components/centered-section-title" */).then(c => wrapFunctional(c.default || c))
export const ContactCard = () => import('../../components/blocks/ContactCard.vue' /* webpackChunkName: "components/contact-card" */).then(c => wrapFunctional(c.default || c))
export const CtaOne = () => import('../../components/blocks/CtaOne.vue' /* webpackChunkName: "components/cta-one" */).then(c => wrapFunctional(c.default || c))
export const DecorativeTitle = () => import('../../components/blocks/DecorativeTitle.vue' /* webpackChunkName: "components/decorative-title" */).then(c => wrapFunctional(c.default || c))
export const DfmProductsGrid = () => import('../../components/blocks/DfmProductsGrid.vue' /* webpackChunkName: "components/dfm-products-grid" */).then(c => wrapFunctional(c.default || c))
export const DubaiInvestmentFeatures = () => import('../../components/blocks/DubaiInvestmentFeatures.vue' /* webpackChunkName: "components/dubai-investment-features" */).then(c => wrapFunctional(c.default || c))
export const FeatureCardTwo = () => import('../../components/blocks/FeatureCardTwo.vue' /* webpackChunkName: "components/feature-card-two" */).then(c => wrapFunctional(c.default || c))
export const FeaturesGridThree = () => import('../../components/blocks/FeaturesGridThree.vue' /* webpackChunkName: "components/features-grid-three" */).then(c => wrapFunctional(c.default || c))
export const GoingPublicCta = () => import('../../components/blocks/GoingPublicCta.vue' /* webpackChunkName: "components/going-public-cta" */).then(c => wrapFunctional(c.default || c))
export const HalfMediaHalfGrid = () => import('../../components/blocks/HalfMediaHalfGrid.vue' /* webpackChunkName: "components/half-media-half-grid" */).then(c => wrapFunctional(c.default || c))
export const HomeVideoBg = () => import('../../components/blocks/HomeVideoBg.vue' /* webpackChunkName: "components/home-video-bg" */).then(c => wrapFunctional(c.default || c))
export const ListingsForEnterprise = () => import('../../components/blocks/ListingsForEnterprise.vue' /* webpackChunkName: "components/listings-for-enterprise" */).then(c => wrapFunctional(c.default || c))
export const PopupAttachmentButton = () => import('../../components/blocks/PopupAttachmentButton.vue' /* webpackChunkName: "components/popup-attachment-button" */).then(c => wrapFunctional(c.default || c))
export const PriceCardBox = () => import('../../components/blocks/PriceCardBox.vue' /* webpackChunkName: "components/price-card-box" */).then(c => wrapFunctional(c.default || c))
export const SearchListBox = () => import('../../components/blocks/SearchListBox.vue' /* webpackChunkName: "components/search-list-box" */).then(c => wrapFunctional(c.default || c))
export const SpacFaq = () => import('../../components/blocks/SpacFaq.vue' /* webpackChunkName: "components/spac-faq" */).then(c => wrapFunctional(c.default || c))
export const TradingHours = () => import('../../components/blocks/TradingHours.vue' /* webpackChunkName: "components/trading-hours" */).then(c => wrapFunctional(c.default || c))
export const TwoColumnBlock = () => import('../../components/blocks/TwoColumnBlock.vue' /* webpackChunkName: "components/two-column-block" */).then(c => wrapFunctional(c.default || c))
export const VideoList = () => import('../../components/blocks/VideoList.vue' /* webpackChunkName: "components/video-list" */).then(c => wrapFunctional(c.default || c))
export const DfmIrApp = () => import('../../components/blocks/dfm-ir-app.vue' /* webpackChunkName: "components/dfm-ir-app" */).then(c => wrapFunctional(c.default || c))
export const IvestorAppStoreLinks = () => import('../../components/blocks/ivestorAppStoreLinks.vue' /* webpackChunkName: "components/ivestor-app-store-links" */).then(c => wrapFunctional(c.default || c))
export const ContactPerson = () => import('../../components/micro/ContactPerson.vue' /* webpackChunkName: "components/contact-person" */).then(c => wrapFunctional(c.default || c))
export const EmailAddress = () => import('../../components/micro/EmailAddress.vue' /* webpackChunkName: "components/email-address" */).then(c => wrapFunctional(c.default || c))
export const PhoneNumber = () => import('../../components/micro/PhoneNumber.vue' /* webpackChunkName: "components/phone-number" */).then(c => wrapFunctional(c.default || c))
export const PriceChange = () => import('../../components/micro/PriceChange.vue' /* webpackChunkName: "components/price-change" */).then(c => wrapFunctional(c.default || c))
export const WebAddress = () => import('../../components/micro/WebAddress.vue' /* webpackChunkName: "components/web-address" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/ui/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseCard = () => import('../../components/ui/BaseCard.vue' /* webpackChunkName: "components/base-card" */).then(c => wrapFunctional(c.default || c))
export const CardWithImageBg = () => import('../../components/ui/CardWithImageBg.vue' /* webpackChunkName: "components/card-with-image-bg" */).then(c => wrapFunctional(c.default || c))
export const ChartComponent = () => import('../../components/ui/ChartComponent.vue' /* webpackChunkName: "components/chart-component" */).then(c => wrapFunctional(c.default || c))
export const CircularCardSmall = () => import('../../components/ui/CircularCardSmall.vue' /* webpackChunkName: "components/circular-card-small" */).then(c => wrapFunctional(c.default || c))
export const CountryDropdown = () => import('../../components/ui/CountryDropdown.vue' /* webpackChunkName: "components/country-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CustomDropdown = () => import('../../components/ui/CustomDropdown.vue' /* webpackChunkName: "components/custom-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CustomPagination = () => import('../../components/ui/CustomPagination.vue' /* webpackChunkName: "components/custom-pagination" */).then(c => wrapFunctional(c.default || c))
export const DateRange = () => import('../../components/ui/DateRange.vue' /* webpackChunkName: "components/date-range" */).then(c => wrapFunctional(c.default || c))
export const DisclosureCardSmall = () => import('../../components/ui/DisclosureCardSmall.vue' /* webpackChunkName: "components/disclosure-card-small" */).then(c => wrapFunctional(c.default || c))
export const ExportToExcel = () => import('../../components/ui/ExportToExcel.vue' /* webpackChunkName: "components/export-to-excel" */).then(c => wrapFunctional(c.default || c))
export const HorizontalLoader = () => import('../../components/ui/HorizontalLoader.vue' /* webpackChunkName: "components/horizontal-loader" */).then(c => wrapFunctional(c.default || c))
export const LineChartComponent = () => import('../../components/ui/LineChartComponent.vue' /* webpackChunkName: "components/line-chart-component" */).then(c => wrapFunctional(c.default || c))
export const LoaderAnimation = () => import('../../components/ui/LoaderAnimation.vue' /* webpackChunkName: "components/loader-animation" */).then(c => wrapFunctional(c.default || c))
export const LoadingAnimation = () => import('../../components/ui/LoadingAnimation.vue' /* webpackChunkName: "components/loading-animation" */).then(c => wrapFunctional(c.default || c))
export const SingleDatePicker = () => import('../../components/ui/SingleDatePicker.vue' /* webpackChunkName: "components/single-date-picker" */).then(c => wrapFunctional(c.default || c))
export const StockPriceIcon = () => import('../../components/ui/StockPriceIcon.vue' /* webpackChunkName: "components/stock-price-icon" */).then(c => wrapFunctional(c.default || c))
export const VideoButtonModal = () => import('../../components/ui/VideoButtonModal.vue' /* webpackChunkName: "components/video-button-modal" */).then(c => wrapFunctional(c.default || c))
export const VideoCard = () => import('../../components/ui/VideoCard.vue' /* webpackChunkName: "components/video-card" */).then(c => wrapFunctional(c.default || c))
export const VideoThumbnailCard = () => import('../../components/ui/VideoThumbnailCard.vue' /* webpackChunkName: "components/video-thumbnail-card" */).then(c => wrapFunctional(c.default || c))
export const CImage = () => import('../../components/ui/c-image.vue' /* webpackChunkName: "components/c-image" */).then(c => wrapFunctional(c.default || c))
export const CtaImage = () => import('../../components/ui/cta-image.vue' /* webpackChunkName: "components/cta-image" */).then(c => wrapFunctional(c.default || c))
export const Decorator = () => import('../../components/ui/decorator.vue' /* webpackChunkName: "components/decorator" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
