import { render, staticRenderFns } from "./CorporateGovernanceReportCard.vue?vue&type=template&id=21d12f9a"
import script from "./CorporateGovernanceReportCard.vue?vue&type=script&lang=js"
export * from "./CorporateGovernanceReportCard.vue?vue&type=script&lang=js"
import style0 from "./CorporateGovernanceReportCard.vue?vue&type=style&index=0&id=21d12f9a&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopupAttachmentButton: require('/vercel/path0/components/blocks/PopupAttachmentButton.vue').default,CustomDropdown: require('/vercel/path0/components/ui/CustomDropdown.vue').default})
