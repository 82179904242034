import { render, staticRenderFns } from "./arena-platform.vue?vue&type=template&id=57a91646&scoped=true"
import script from "./arena-platform.vue?vue&type=script&lang=js"
export * from "./arena-platform.vue?vue&type=script&lang=js"
import style0 from "./arena-platform.vue?vue&type=style&index=0&id=57a91646&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57a91646",
  null
  
)

/* custom blocks */
import block0 from "./locale/arena-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fraise-capital%2Flisting-services%2Farena-platform.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/arena-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fraise-capital%2Flisting-services%2Farena-platform.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageFill: require('/vercel/path0/components/ImageFill.vue').default,Decorator: require('/vercel/path0/components/ui/decorator.vue').default,PageIntroBlockTwo: require('/vercel/path0/components/PageIntroBlockTwo.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,VideoThumbnailCard: require('/vercel/path0/components/ui/VideoThumbnailCard.vue').default,FaqsAccordion: require('/vercel/path0/components/datablocks/FaqsAccordion.vue').default,CtaOne: require('/vercel/path0/components/blocks/CtaOne.vue').default})
