import { render, staticRenderFns } from "./direct-deals.vue?vue&type=template&id=0553225a&scoped=true"
import script from "./direct-deals.vue?vue&type=script&lang=js"
export * from "./direct-deals.vue?vue&type=script&lang=js"
import style0 from "./direct-deals.vue?vue&type=style&index=0&id=0553225a&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0553225a",
  null
  
)

/* custom blocks */
import block0 from "./locale/direct-deals-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fmarket-information%2Fdirect-deals.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/direct-deals-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fmarket-information%2Fdirect-deals.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,DownloadCard: require('/vercel/path0/components/DownloadCard.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default,LoadingAnimation: require('/vercel/path0/components/ui/LoadingAnimation.vue').default,SearchInput: require('/vercel/path0/components/SearchInput.vue').default,DateRange: require('/vercel/path0/components/ui/DateRange.vue').default})
