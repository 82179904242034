import { render, staticRenderFns } from "./why-dfm.vue?vue&type=template&id=33912a77"
import script from "./why-dfm.vue?vue&type=script&lang=js"
export * from "./why-dfm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/why-dfm-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fdiscover-dfm%2Fwhy-dfm.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/why-dfm-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fdiscover-dfm%2Fwhy-dfm.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,PageIntroBlock: require('/vercel/path0/components/PageIntroBlock.vue').default,Decorator: require('/vercel/path0/components/ui/decorator.vue').default,FeaturesGridTwo: require('/vercel/path0/components/FeaturesGridTwo.vue').default,FeaturesGridThree: require('/vercel/path0/components/blocks/FeaturesGridThree.vue').default,VueGlideComponent: require('/vercel/path0/components/vue-glide-component.vue').default,DfmIrApp: require('/vercel/path0/components/blocks/dfm-ir-app.vue').default})
