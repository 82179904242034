import { render, staticRenderFns } from "./ListedCarbonCredits.vue?vue&type=template&id=71e2ed4e"
import script from "./ListedCarbonCredits.vue?vue&type=script&lang=js"
export * from "./ListedCarbonCredits.vue?vue&type=script&lang=js"
import style0 from "./ListedCarbonCredits.vue?vue&type=style&index=0&id=71e2ed4e&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomPagination: require('/vercel/path0/components/ui/CustomPagination.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default,Tab: require('/vercel/path0/components/Tab.vue').default,Tabs: require('/vercel/path0/components/Tabs.vue').default,SearchListBox: require('/vercel/path0/components/blocks/SearchListBox.vue').default,CustomDropdown: require('/vercel/path0/components/ui/CustomDropdown.vue').default})
