import { render, staticRenderFns } from "./default.vue?vue&type=template&id=5d658f39"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainNavigation: require('/vercel/path0/components/MainNavigation.vue').default,HeaderInfoBar: require('/vercel/path0/components/HeaderInfoBar.vue').default,TailwindBreakpoints: require('/vercel/path0/components/TailwindBreakpoints.vue').default})
