import { render, staticRenderFns } from "./brokers-ranking.vue?vue&type=template&id=b87fdafe&scoped=true"
import script from "./brokers-ranking.vue?vue&type=script&lang=js"
export * from "./brokers-ranking.vue?vue&type=script&lang=js"
import style0 from "./brokers-ranking.vue?vue&type=style&index=0&id=b87fdafe&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b87fdafe",
  null
  
)

/* custom blocks */
import block0 from "./brokers-ranking.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,LoadingAnimation: require('/vercel/path0/components/ui/LoadingAnimation.vue').default,SearchInput: require('/vercel/path0/components/SearchInput.vue').default,CustomDropdown: require('/vercel/path0/components/ui/CustomDropdown.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default})
