import { render, staticRenderFns } from "./ipo-accelerator-programme.vue?vue&type=template&id=6a2d132d"
import script from "./ipo-accelerator-programme.vue?vue&type=script&lang=js"
export * from "./ipo-accelerator-programme.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/ipo-accelerator-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fraise-capital%2Flisting-services%2Fipo-accelerator-programme.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/ipo-accelerator-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fraise-capital%2Flisting-services%2Fipo-accelerator-programme.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,PageIntroBlock: require('/vercel/path0/components/PageIntroBlock.vue').default,Decorator: require('/vercel/path0/components/ui/decorator.vue').default,FeaturesGridTwo: require('/vercel/path0/components/FeaturesGridTwo.vue').default,HalfImageHalfGrid: require('/vercel/path0/components/HalfImageHalfGrid.vue').default,TeamMember: require('/vercel/path0/components/datablocks/TeamMember.vue').default,PhoneNumber: require('/vercel/path0/components/micro/PhoneNumber.vue').default,EmailAddress: require('/vercel/path0/components/micro/EmailAddress.vue').default,FloatingModal: require('/vercel/path0/components/FloatingModal.vue').default,VueSimpleAccordion: require('/vercel/path0/components/VueSimpleAccordion.vue').default})
