import { render, staticRenderFns } from "./reports.vue?vue&type=template&id=7784a24e"
import script from "./reports.vue?vue&type=script&lang=js"
export * from "./reports.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./reports.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SecurityReports: require('/vercel/path0/components/SecurityReports.vue').default,Tab: require('/vercel/path0/components/Tab.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default,DisclosureCard: require('/vercel/path0/components/DisclosureCard.vue').default,Tabs: require('/vercel/path0/components/Tabs.vue').default})
