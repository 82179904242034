import { render, staticRenderFns } from "./listing-reits.vue?vue&type=template&id=62440f98"
import script from "./listing-reits.vue?vue&type=script&lang=js"
export * from "./listing-reits.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/listing-reits-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fraise-capital%2Flisting-options%2Flisting-reits.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/listing-reits-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fraise-capital%2Flisting-options%2Flisting-reits.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,PageIntroBlock: require('/vercel/path0/components/PageIntroBlock.vue').default,Decorator: require('/vercel/path0/components/ui/decorator.vue').default,FeaturesGridTwo: require('/vercel/path0/components/FeaturesGridTwo.vue').default,FeatureCardTwo: require('/vercel/path0/components/blocks/FeatureCardTwo.vue').default,TwoColumnBlock: require('/vercel/path0/components/blocks/TwoColumnBlock.vue').default,HalfImageHalfGrid: require('/vercel/path0/components/HalfImageHalfGrid.vue').default})
