import { render, staticRenderFns } from "./ownership-holding-statistics.vue?vue&type=template&id=510af804&scoped=true"
import script from "./ownership-holding-statistics.vue?vue&type=script&lang=js"
export * from "./ownership-holding-statistics.vue?vue&type=script&lang=js"
import style0 from "./ownership-holding-statistics.vue?vue&type=style&index=0&id=510af804&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510af804",
  null
  
)

/* custom blocks */
import block0 from "./locale/statistics-reports-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fstatistics-reports%2Fownership-holding-statistics.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/statistics-reports-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fstatistics-reports%2Fownership-holding-statistics.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,LoadingAnimation: require('/vercel/path0/components/ui/LoadingAnimation.vue').default,SearchInput: require('/vercel/path0/components/SearchInput.vue').default,SingleDatePicker: require('/vercel/path0/components/ui/SingleDatePicker.vue').default,FootNote: require('/vercel/path0/components/FootNote.vue').default})
