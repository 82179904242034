import { render, staticRenderFns } from "./CarbonCreditsMarketWatch.vue?vue&type=template&id=265ab78b&scoped=true"
import script from "./CarbonCreditsMarketWatch.vue?vue&type=script&lang=js"
export * from "./CarbonCreditsMarketWatch.vue?vue&type=script&lang=js"
import style0 from "./CarbonCreditsMarketWatch.vue?vue&type=style&index=0&id=265ab78b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "265ab78b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarketStatus: require('/vercel/path0/components/MarketStatus.vue').default,LoaderAnimation: require('/vercel/path0/components/ui/LoaderAnimation.vue').default,PriceChange: require('/vercel/path0/components/micro/PriceChange.vue').default})
