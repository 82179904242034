import { render, staticRenderFns } from "./HalfImageHalfGrid.vue?vue&type=template&id=5047ecda"
import script from "./HalfImageHalfGrid.vue?vue&type=script&lang=js"
export * from "./HalfImageHalfGrid.vue?vue&type=script&lang=js"
import style0 from "./HalfImageHalfGrid.vue?vue&type=style&index=0&id=5047ecda&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CImage: require('/vercel/path0/components/ui/c-image.vue').default})
