import { render, staticRenderFns } from "./GlobalNotificationBar.vue?vue&type=template&id=0ad329e6"
import script from "./GlobalNotificationBar.vue?vue&type=script&lang=js"
export * from "./GlobalNotificationBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./GlobalNotificationBar.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default})
