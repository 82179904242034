import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=6385bf91"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default})
