import { render, staticRenderFns } from "./settlement-trading-calender.vue?vue&type=template&id=596c55fa"
import script from "./settlement-trading-calender.vue?vue&type=script&lang=js"
export * from "./settlement-trading-calender.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./settlement-trading-calender.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,DownloadCard: require('/vercel/path0/components/DownloadCard.vue').default})
