import { render, staticRenderFns } from "./weekly-margin-trading-report.vue?vue&type=template&id=611dd972&scoped=true"
import script from "./weekly-margin-trading-report.vue?vue&type=script&lang=js"
export * from "./weekly-margin-trading-report.vue?vue&type=script&lang=js"
import style0 from "./weekly-margin-trading-report.vue?vue&type=style&index=0&id=611dd972&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "611dd972",
  null
  
)

/* custom blocks */
import block0 from "./locale/statistics-reports-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fstatistics-reports%2Fweekly-margin-trading-report.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/statistics-reports-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fstatistics-reports%2Fweekly-margin-trading-report.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,LoadingAnimation: require('/vercel/path0/components/ui/LoadingAnimation.vue').default,SingleDatePicker: require('/vercel/path0/components/ui/SingleDatePicker.vue').default,FootNote: require('/vercel/path0/components/FootNote.vue').default})
