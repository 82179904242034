import { render, staticRenderFns } from "./MarketStatus.vue?vue&type=template&id=dfcd0694&scoped=true"
import script from "./MarketStatus.vue?vue&type=script&lang=js"
export * from "./MarketStatus.vue?vue&type=script&lang=js"
import style0 from "./MarketStatus.vue?vue&type=style&index=0&id=dfcd0694&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfcd0694",
  null
  
)

export default component.exports