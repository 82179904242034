import { render, staticRenderFns } from "./suhoor-gathering.vue?vue&type=template&id=e9c19e70"
import script from "./suhoor-gathering.vue?vue&type=script&lang=js"
export * from "./suhoor-gathering.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CImage: require('/vercel/path0/components/ui/c-image.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default})
