import { render, staticRenderFns } from "./TradingHours.vue?vue&type=template&id=b011b9ca"
import script from "./TradingHours.vue?vue&type=script&lang=js"
export * from "./TradingHours.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseCard: require('/vercel/path0/components/ui/BaseCard.vue').default})
