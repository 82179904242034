import { render, staticRenderFns } from "./track-nominations.vue?vue&type=template&id=95b6b976&scoped=true"
import script from "./track-nominations.vue?vue&type=script&lang=js"
export * from "./track-nominations.vue?vue&type=script&lang=js"
import style0 from "./track-nominations.vue?vue&type=style&index=0&id=95b6b976&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95b6b976",
  null
  
)

/* custom blocks */
import block0 from "./locale/eboard-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fnews-disclosures%2Fe-board%2Ftrack-nominations.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/eboard-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fnews-disclosures%2Fe-board%2Ftrack-nominations.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports