import { render, staticRenderFns } from "./corporate-actions.vue?vue&type=template&id=1301e287"
import script from "./corporate-actions.vue?vue&type=script&lang=js"
export * from "./corporate-actions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoaderAnimation: require('/vercel/path0/components/ui/LoaderAnimation.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default,CompanyCorporateActions: require('/vercel/path0/components/datablocks/CompanyCorporateActions.vue').default})
