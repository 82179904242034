import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4aff635c"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./../locale/circulars-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fregulation%2Fcirculars%2Findex.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./../locale/circulars-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fregulation%2Fcirculars%2Findex.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)
import block2 from "./index.vue?vue&type=custom&index=2&blockType=i18n"
if (typeof block2 === 'function') block2(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,LoadingAnimation: require('/vercel/path0/components/ui/LoadingAnimation.vue').default,CircularListCard: require('/vercel/path0/components/CircularListCard.vue').default})
